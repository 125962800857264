import  './GetStarted.css'
import { useNavigate } from 'react-router-dom'
const GetStarted = () => {
  const navigate = useNavigate()
  return (
    <div className='getstarted' onClick={()=>navigate('/contact')}>
        <p>Want a quick solution to that problem?</p>
        <h2>Get started</h2>
        <img src="/assets/arr.svg" alt="" onClick={()=>navigate('/contact')} />
    </div>
  )
}

export default GetStarted