

import './ServicesDetails.css'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";

const ServicesDetails = () => {

  const navigate = useNavigate()

  useEffect(() => {
    AOS.init();
   
  }, [])
  return (
    <div className="services-flex">
      <div className="servi-flex-odd">

      
      <div className="services-box-left">
        <h1>1. Data Services</h1>
          <img src="/assets/ss1.svg" alt=""loading='lazy'/>
      </div>
      <div className="services-box-right" >
        <h2>1</h2>
      <h1>Data Services</h1>
        <p>There is a massive amount of insight data can give your organisation when it is cleansed, profiled, labelled, cardinal, properly managed, and stored right. Data never goes out of fashion and can be constantly reused for insight, analytics, visualisation and reporting</p>
        <button onClick={()=>navigate('/dataservices')}>Learn More</button>
      </div>
      </div>
      <div className="servi-flex">
      <div className="services-box-right" > 
      <h2>2</h2>
      <h1>Applications</h1>
        <p>Our App team are subject matter experts; we create robust, reliable and cost-effective web based,  mobile (stand-alone) and integrated Apps for various industries.</p>
        <button onClick={()=>navigate('/apps')}>Learn More</button>
      </div>
     
     
      <div className="services-box-left">
        <h1>2. Applications</h1>
          <img src="/assets/ss2.svg" alt="" loading='lazy' />
      </div>
      </div>
      <div className="servi-flex-odd">
      <div className="services-box-left">
        <h1>3. Artificial Intelligence</h1>
          <img src="/assets/rr.svg" alt="" loading='lazy' />
      </div>
      <div className="services-box-right" >
        <h2>3</h2>
        <h1>Artificial intelligence</h1>
        <p>Businesses today are fast embracing AI Machine Learning. Our team of data scientists and programmers can turn your concepts into models and train them for the maximum efficiency – you just enjoy the output</p>

        <button onClick={()=>navigate('/artificialintelligence')}>Learn More</button>
      </div>
      </div>
      <div className="servi-flex">
      <div className="services-box-right">
        <h2>4</h2>
        <h1>Infrastructure</h1>
        <p>Let's take the stress off your infrastructure strategy. We love what we do and it shows. Our team have vast experiences in the field of infrastructure design whether on premise or in the cloud.
        Our projects include reliable, robust and scalable system upgrades, Paas (Platform as a service), Iaas (Infrastructure as a service) and Daas (Desktop as a service).</p>
        <button onClick={()=>navigate('/infrastructure')}>Learn More</button>
      </div>
      
      <div className="services-box-left">
         <h1>4. Infrastructure</h1>
          <img src="/assets/inf.svg" alt="" loading='lazy' />
      </div>
      </div>
      <div className="servi-flex-odd">

      
      <div className="services-box-left">
        <h1>5. Consulting</h1>
          <img src="/assets/se2.svg" alt=""loading='lazy'/>
      </div>
      <div className="services-box-right" >
        <h2>5</h2>
      <h1>Consulting</h1>
        <p>Our team of consultants can give hands on advice from infrastructure in the cloud or on premise. From change management, business intelligence, technological information, organisational change, infrastructural acquisition, business analysis, IoT, artificial intelligence, business, analysis, visualisation and data.</p>
        <button onClick={()=>navigate('/consulting')}>Learn More</button>
      </div>
      </div>
      
      <div className="servi-flex">
      <div className="services-box-right">
      <h2>6</h2>
        <h1>Robotic Process Automation</h1>
        <p>Robotics Process Automation is a form of business process automation technology based on software robots, artificial intelligence, digital workers to carry out tedious tasks effectively. We help you automate processes without increasing costs or headcount, reducing your staffing costs and human error</p>
        <button onClick={()=>navigate('/robotics')}>Learn More</button>
      </div>
      
      <div className="services-box-left">
          <h1>6. Robotic Process Automation</h1>
          <img src="/assets/ru.svg" alt="" loading='lazy' />
      </div>
      </div>
      

    </div>
  )
}

export default ServicesDetails