import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './DataTabs.css'

const DataTabs = () => {
    const [first , setFirst ] = useState(true)
const [second , setSecond] = useState(false)
const [third , setThird] = useState(false)
const [fourth, setFourth]= useState(false )
const [fifth , setFifth] = useState(false)
const [sixth , setSixth] = useState(false)

const navigate = useNavigate()

const showFirst = ()=>{
    setFirst(true)
    setSecond(false)
    setThird(false)
    setFourth(false )
    setFifth(false)
    setSixth(false)

}

const showSecond = ()=>{
    setFirst(false)
    setSecond(true)
    setThird(false)
    setFourth(false )
    setFifth(false)
    setSixth(false)

}

const showThird = ()=>{
    setFirst(false)
    setSecond(false)
    setThird(true)
    setFourth(false )
    setFifth(false)
    setSixth(false)

}

const showFourth = ()=>{
    setFirst(false)
    setSecond(false)
    setThird(false)
    setFourth(true)
    setFifth(false)
    setSixth(false)

}

const showFifith = ()=>{
    setFirst(false)
    setSecond(false)
    setThird(false)
    setFourth(false )
    setFifth(true)
    setSixth(false)

}

const showSixth = ()=>{
    setFirst(false)
    setSecond(false)
    setThird(false)
    setFourth(false )
    setFifth(false)
    setSixth(true)

}
  return (
    <div className='data-tabs-container'>
        <div className="data-tabs">
            <p onClick={showFirst} style={first ? { color: 'black',textDecoration:'underline 4px #2E7DDA'}: {color: 'rgba(49, 49, 49, 0.3)'}}>Data Labelling & Rating</p>
            <p onClick={showSecond} style={second ? { color: 'black',textDecoration:'underline 4px #2E7DDA'}: {color: 'rgba(49, 49, 49, 0.3)'}}>Data Migration</p>
            <p onClick={showThird} style={third ? { color: 'black',textDecoration:'underline 4px #2E7DDA'}: {color: 'rgba(49, 49, 49, 0.3)'}}>Taming Big Data</p>
            <p onClick={showFourth} style={fourth ? { color: 'black',textDecoration:'underline 4px #2E7DDA'}: {color: 'rgba(49, 49, 49, 0.3)'}}>Data Storage</p>
            <p onClick={showFifith} style={fifth ? { color: 'black',textDecoration:'underline 4px #2E7DDA'}: {color: 'rgba(49, 49, 49, 0.3)'}}>Business Intelligence</p>
            <p onClick={showSixth} style={sixth ? { color: 'black',textDecoration:'underline 4px #2E7DDA'}: {color: 'rgba(49, 49, 49, 0.3)'}}>Data Science & Engineering</p>
        </div>
<div className="data-tabs-flex">
       {first && <div className="servi-flex">
    <div className="services-box-right"> 
   
    <h1>Data Labelling & Rating </h1>
      <p  style={{marginTop: '18px'}} >Have massive amounts of data you want to use for machine learning or deep learning? You’ll need to enrich it so you can train, validate, and tune your model.

Data-labelling provides an agile and scalable approach elevating accuracy, consistency, and speed.
</p>
      <button onClick={()=>navigate('/contact')}>Contact Us</button>
    </div>
   
   
    <div className="services-box-left">
    <h1>Data Labelling & Rating </h1>
        <img src="/assets/dd1.svg" alt="" loading='lazy' />
    </div>
    </div>}
        {second && <div className="servi-flex">
    <div className="services-box-right"> 
   
    <h1>Data Migration</h1>
      <p  style={{marginTop: '18px'}} >Our team of consultants has vast experience in both data and system migration as well as upgrades and can perform your migration with flawless accuracy.

</p>
<button onClick={()=>navigate('/contact')}>Contact Us</button>
    </div>
   
   
    <div className="services-box-left">
    <h1>Data Migration</h1>
        <img src="/assets/dd2.svg" alt="" loading='lazy' />
    </div>
    </div>}
        {third && <div className="servi-flex">
    <div className="services-box-right"> 
   
    <h1>Taming Big Data</h1>
      <p  style={{marginTop: '18px'}} >Process continuous streams of data real time. Share information between nodes. Implement iterative algorithms.

Tune and troubleshoot clustered large jobs. Process and analyze large data sets across many CPUs. Whenever your big data needs a leash… we can tame it.

</p>
<button onClick={()=>navigate('/contact')}>Contact Us</button>
    </div>
   
   
    <div className="services-box-left">
    <h1>Taming Big Data</h1>
        <img src="/assets/dd3.svg" alt="" loading='lazy' />
    </div>
    </div>}
       {fourth &&  <div className="servi-flex">
    <div className="services-box-right"> 
   
    <h1>Data Storage</h1>
      <p  style={{marginTop: '18px'}} >Our range of data storage solutions from on premise to cloud are easy and reliable. No matter the data size ,we are unrivaled and regulation compliant. Scale Your business with our range of solutions.

</p>
<button onClick={()=>navigate('/contact')}>Contact Us</button>
    </div>
   
   
    <div className="services-box-left">
    <h1>Data Storage</h1>
        <img src="/assets/dd4.svg" alt="" loading='lazy' />
    </div>
    </div>}
        {fifth && <div className="servi-flex">
    <div className="services-box-right"> 
   
    <h1>Business Intelligence </h1>
      <p  style={{marginTop: '18px'}} >Business Intelligence (BI) and Analytics methods make businesses and associations progressively proficient, productive and give a competitive advantage.

Organisations are constantly churning out large volumes of data. Powerful Business Intelligence tools guarantee data becomes valuable, agile and engaging.

Our tools turns your data into a highly insightful game-changer while powerful data visualisation shows extra information.
</p>
<button onClick={()=>navigate('/contact')}>Contact Us</button>
    </div>
   
   
    <div className="services-box-left">
    <h1>Business Intelligence </h1>
        <img src="/assets/dd5.svg" alt="" loading='lazy' />
    </div>
    </div>}
       {sixth && <div className="servi-flex">
    <div className="services-box-right"> 
   
    <h1>Data Science & Engineering</h1>
      <p  style={{marginTop: '18px'}} >If your organisation stores data in different formats across various databases, then you can benefit from our data engineers and data scientists.

Our scientist will build your models, our engineers will create your data pipeline

</p>
<button onClick={()=>navigate('/contact')}>Contact Us</button>
    </div>
   
   
    <div className="services-box-left">
    <h1>Data Science & Engineering</h1>
        <img src="/assets/dd6.svg" alt="" loading='lazy' />
    </div>
    </div>}
    </div>

    {first && <div className="move-btns">
        <button onClick={showSixth}>Previous</button>
        <button onClick={showSecond}>Next</button>
    </div> }
    {second && <div className="move-btns">
        <button onClick={showFirst}>Previous</button>
        <button onClick={showThird}>Next</button>
    </div> }
    {third && <div className="move-btns">
        <button onClick={showSecond}>Previous</button>
        <button onClick={showFourth}>Next</button>
    </div> }
    {fourth && <div className="move-btns">
        <button onClick={showThird}>Previous</button>
        <button onClick={showFifith}>Next</button>
    </div> }
    {fifth && <div className="move-btns">
        <button onClick={showFourth}>Previous</button>
        <button onClick={showSixth}>Next</button>
    </div> }
    {sixth && <div className="move-btns">
        <button onClick={showFifith}>Previous</button>
        <button onClick={showFirst}>Next</button>
    </div> }

    </div>
  )
}

export default DataTabs