import './Testimonials.css'

const Testimonials = () => {
  return (
    <div className='testimonials'>
        <div className="testi-heading">
            <h1>Your Work Starts With Your Business  Objectives</h1>
            <p>We operate within the broader strategic context of your business. What problems are we trying to solve? What is our starting point? What needs to change?</p>
        </div>
        <div className="testi-dia-flex">
        <div className="testi-dia">
            <h2>WHAT WE DO</h2>
            <p>We start with a strong strategic foundation and build on it, step by step, making it run like clockwork</p>
            <img src="/assets/ffe1.svg" alt="" />
        </div>
        <div className="testi-dia">
            <h2>HOW WE THINK</h2>
            <p>We see people, processes and technology as the cornerstones with data at the core of everything we do</p>
            <img src="/assets/ffe2.svg" alt="" />
        </div>
        <div className="testi-dia">
            <h2>WHERE WE WORK</h2>
            <p>Our modular services are designed to work alone or together adding flexibility and value to your business</p>
            <img src="/assets/newcircle.svg" alt="" id='imgodd' />
        </div>
        </div>
    </div>
  )
}

export default Testimonials