import { useNavigate } from "react-router-dom"

const InfrastructureBody = () => {
    const  navigate = useNavigate()
  return (
    <div className="services-flex">
    <div className="servi-flex-odd">

    
    <div className="services-box-left">
    <h1>1. Infrastructure Without Hassle </h1>
        <img src="/assets/inn1.svg" alt=""loading='lazy'/>
    </div>
    <div className="services-box-right" >
   
    <h1>Infrastructure Without Hassle </h1>
      <p>Let's take the stress off your infrastructure strategy. We love what we do and it shows.

Our projects include reliable, robust and scalable system upgrades, Paas (Platform as a service), laas (Infrastructure as a service) and Daas (Desktop as a service).</p>
      <button onClick={()=>navigate('/contact')}>Contact Us</button>
    </div>
    </div>
    <div className="servi-flex">
    <div className="services-box-right"> 
   
    <h1>There’s No Challenge Too Big </h1>
      <p>Upgrades, cloud infrastructure, machine learning, infrastructure improvements and analysis.

Infrastructure migration (both on-premise and cloud), architecture, consultancy, GDPR compliance service infrastructure/Appliance support, Consultancy, Repairs & Calibration, Platforms, Resource, augmentation and Procurement. 

We know our industry like the back of our hands. There's no challenge too big or too small and we dedicate our utmost energy to every project we take on. We also have a solid network of partners and can take up projects very quickly with genius level expertise.</p>
      <button onClick={()=>navigate('/contact')}>Contact Us</button>
    </div>
   
   
    <div className="services-box-left">
    <h1>2. There’s No Challenge Too Big </h1>
        <img src="/assets/inn2.svg" alt="" loading='lazy' />
    </div>
    </div>
    <div className="servi-flex-odd">
    <div className="services-box-left">
    <h1>3. Desktop-As-A-Service (Daas)</h1>
        <img src="/assets/inn3.svg" alt="" loading='lazy' />
    </div>
    <div className="services-box-right" >
     
      <h1>Desktop-As-A-Service (Daas)</h1>
      <p>Desktops as a Service securely delivers virtual apps and desktops from the cloud to any device or location, perfect for companies with teams globally.

Desktop as a service provides all the advantages of virtual desktop infrastructure, including remote worker support, improved security and ease of desktop management.</p>

      <button onClick={()=>navigate('/contact')}>Contact Us</button>
    </div>


   
    </div>
    

  
    

  </div>
  )
}

export default InfrastructureBody