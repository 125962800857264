import './ProductBody.css'
import { useEffect } from 'react';

import AOS from "aos";
import { useNavigate } from 'react-router-dom';
import "aos/dist/aos.css"
const ProductBody = () => {
  const navigate = useNavigate()
  return (
    <div className="services-flex">
    <div className="servi-flex-odd">

    
    <div className="services-box-left">
       <h1>1. Digital Transformation</h1>
        <img src="/assets/ser.svg" alt=""loading='lazy'/>
    </div>
    <div className="services-box-right" >

    <h1>Digital Transformation</h1>
      <p>We create your digital transformation framework and implement it.</p>
      <button onClick={()=>navigate('/contact')}>Contact us</button>
    </div>
    </div>
    <div className="servi-flex">
    <div className="services-box-right" > 

    <h1>System Migration</h1>
      <p>Want to move your systems or programs , we offer Legacy systems migration , Cloud Migration and Cloud Adoption </p>
      <button onClick={()=>navigate('/contact')}>Contact us</button>
    </div>
   
   
    <div className="services-box-left">
    
        <h1>2. System Migration</h1>
        <img src="/assets/ii3.svg" alt="" loading='lazy' />
    </div>
    </div>
    <div className="servi-flex-odd">
    <div className="services-box-left">
    <h1>3. Business intelligence</h1>
        <img src="/assets/serv3.svg" alt="" loading='lazy' />
    </div>
    <div className="services-box-right" >
      
      <h1>Business intelligence</h1>
      <p>We offer a variety of Analytics ,  Visualisation , Data engineering , Data discovery also Build automation and prediction </p>

      <button onClick={()=>navigate('/contact')}>Contact us</button>
    </div>
    </div>
    <div className="servi-flex">
    <div className="services-box-right">
      
      <h1>Consultancy</h1>
      <p>Need data-driven advice and consultant for your IT projects ?</p>
      <button onClick={()=>navigate('/contact')}>Contact us</button>
    </div>
    
    <div className="services-box-left">
    <h1>4. Consultancy</h1>
        <img src="/assets/se1.svg" alt="" loading='lazy' />
    </div>
    </div>
 
    

  </div>
  )
}

export default ProductBody