import GetIn from '../../components/GetIn/GetIn'
import Footer from '../../components/Home-components/Footer/Footer'
import RoboticsBody from '../../components/Robotics-components/RoboticsBody'
import './Robotics.css'


const Robotics = () => {
  return (
    <div>
    <div className="robotics-top">
      <div className="robotics-cover">
          <h1>Robotic Process Automation</h1>
          <p>Robotic Process Automation(RPA) Allows you to automate Repititive , Error-prone manual processes 
          Lower Costs and minimize human errors with hyper automation. 
             </p>
          </div>
    </div>
  
   
<RoboticsBody />
 <GetIn />
   <Footer />

  </div>
  )
}

export default Robotics