import AppsBody from '../../components/Apps-components/AppsBody'
import GetIn from '../../components/GetIn/GetIn'
import Footer from '../../components/Home-components/Footer/Footer'
import './Apps.css'

const Apps = () => {
  return (
    <div>
    <div className="apps-top">
      <div className="data-cover">
          <h1>Apps</h1>
          <p>Our App team are subject matter experts that create robust, reliable and cost-effective Apps, native Apps (stand-alone) and integrated Apps for various industries.</p>
          </div>
    </div>
  
   <AppsBody />
  <GetIn />

   <Footer />
   </div>
  )
}

export default Apps