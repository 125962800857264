import './Company.css'

const Company = () => {
  return (
    <div>
        <div className="about-c-top">
            <img src="/assets/company.svg" alt="" loading='lazy'/>
        </div>
        <div className="about-c-bottom">
        <h1>WELCOME TO WORK</h1>
            <p>Our products and services are tailored to fit the exact scenario. Our consultants work extensively to understand the situatiion and map out simple and fit for purpose solutions.
            </p> 

            <p> We are model driven and will create strategy to help achieve expected customer results.
            </p>
            <p>Build and maintain customer relationships as we know our customers and are commited to their success.</p> 
<br />
            <p> We provide ongoing support and are on call 24/7 to solve customer needs.</p>
        </div>
    </div>
  )
}

export default Company