import './SignUp.css'
import { useState } from 'react'
import axios from 'axios'
const SignUp = () => {
  const [email ,setEmail] = useState('')

  const [message , setMessage] = useState('')
  const submit = async (e) =>{
    e.preventDefault()
    const response = await axios.post('https://aipidata.herokuapp.com/api/v1/newsletters/join' , {email})
    
    setMessage(response.data.message)
    setEmail('')
  }
  return (
    <div className='signup'>
        <div className="signup-left">
            <img src="/assets/foot.svg" alt="" loading='lazy' />
        </div>
        <div className="signup-right">
          <p>We got a tech solution for that. Get in touch with our team to discuss your business needs</p>
            <h3>Sign up to be updated on our events</h3>
            <p style={{color: 'green' , fontSize: '13px'}}> {message} </p>
            <div className="input" onSubmit={submit}>
              <form action="">
                <input type="text" value={email} name="email" onChange={(e)=>setEmail(e.target.value)} />
                <button type='submit'>Subscribe</button>
                </form>
            </div>
        </div>
    </div>
  )
}

export default SignUp