import Footer from '../../components/Home-components/Footer/Footer'
import './Cont.css'
import { useState } from 'react'
import axios from 'axios'
const Cont = () => {

    const [firstName , setFirstName] = useState('')
    const [lastName , setLastName] = useState('')
    const [email , setEmail] = useState('')
    const [message ,setMessage] = useState('')

    const [result , setResult] = useState('')

    const form = {firstName , lastName , email , message}

    const submit = async (e) =>{
        e.preventDefault()
        const response = await axios.post('https://aipidata.herokuapp.com/api/v1/contacts',form )
     
        setResult(response.data.message)
        if(response){
            setFirstName('')
            setLastName('')
            setEmail('')
            setMessage('')

        }
    }
  return (
    <div className='cont-container'>
          <div className="cont-top">
          <div className="n-cover">
         <h1>Contact us</h1>
       </div>
            </div>
    <div className='cont'>
        
        <div className="cont-heading">
            <h1>CONTACT</h1>
            <p>For more information, fill your contact details and we will  get back to you</p>
        </div>
        <div className="cont-flex">
            <div className="cont-box-1">
            <p>We’d love to hear from you. Choose the most convenient method and we will get in touch.</p>
            <p style={{color: 'green' , fontSize: '13px'}}> {result} </p>
            <div className="cont-form">
                <form action="" onSubmit={submit}>

          
                <label>First Name</label>
                <input type="text" onChange={(e)=>setFirstName(e.target.value)} value={firstName} name="firstName" />
                <label>Last Name</label>
                <input type="text" onChange={(e)=>setLastName(e.target.value)} value={lastName} name="lastName" />
                <label >Email Adress</label>
                <input type="text" onChange={(e)=>setEmail(e.target.value)} value={email} name="email" />
                <label >Message</label>
                <input type="text" onChange={(e)=>setMessage(e.target.value)} value={message} name="message" />

                <button type='submit'> Send Message</button>

                </form>
            </div>
            </div>
            <div className="cont-box-2">
                <h1>CONTACT US</h1>

                <div className="cont-items">
                    <img src="/assets/cont1.svg" alt="" />
                    <p>20, Swan street city centre Manchester, Uk</p>
                </div>
                <div className="cont-items">
                    <img src="/assets/cont2.svg" alt="" />
                    <p>+44 1613121772
</p>
                </div>
                <div className="cont-items">
                    <img src="/assets/cont3.svg" alt="" />
                    <p>Info@aipidata.com</p>
                </div>
            </div>
        </div>
    </div>
    <Footer />
    </div>
  )
}

export default Cont