import DataAnybody from '../../components/DataAnalytics-components/DataAnybody'
import DataTabs from '../../components/DataAnalytics-components/DataTabs/DataTabs'
import GetIn from '../../components/GetIn/GetIn'
import Footer from '../../components/Home-components/Footer/Footer'
import './DataAnalytics.css'
const DataAnalytics = () => {
  return (
    <div>
      <div className="data-top">
        <div className="data-cover">
            <h1>Data Services</h1>
            <p>Data is the new oil. There is a a massive amount of insight data can give your organisation when it is cleansed, profiled, labelled, cardinal, properly managed and stored right. Data never goes out of fashion and can be constantly reused for insight, analytics, visualisation and reporting.</p>
            </div>
      </div>
    
     <DataAnybody />
     <DataTabs />
     <GetIn />
   
     <Footer />

    </div>
  )
}

export default DataAnalytics