import { useNavigate } from "react-router-dom"
const Aibody = () => {

  const navigate = useNavigate()
  return (
    <div className="services-flex">
    <div className="servi-flex-odd">

    
    <div className="services-box-left">
    <h1>1. Bespoke Al Models</h1>
        <img src="/assets/ai1.svg" alt=""loading='lazy'/>
    </div>
    <div className="services-box-right" >
   
    <h1>Bespoke Al Models</h1>
      <p>Do you have  a business requirement or concept that could benefit from Machine Learning and AI?
      We specialize in Natural Language Processing(NLP), Computer Vision , Predictive Structures , Artificial Neural Networks , PCA , etc...
</p>
      <button onClick={()=>navigate('/contact')}>Contact Us</button>
    </div>
    </div>
    <div className="servi-flex">
    <div className="services-box-right"> 
   
    <h1>Predictive Maintenance </h1>
      <p>Maintenance bill makes you want to tear your hair out? Our technology lets you predict equipment malfunctions before they happen and predict the lifecycle of products, thus allowing you to purchase the best parts and products thus reducing your maintenance bills. 

Our Stardust tool is modelled specifically for the needs of the Energy industry and industries that operate heavy machinery that requires constant maintenance.
</p>
      <button onClick={()=>navigate('/contact')}>Contact Us</button>
    </div>
   
   
    <div className="services-box-left">
    <h1>2. Predictive Maintenance </h1>
        <img src="/assets/ai2.svg" alt="" loading='lazy' />
    </div>
    </div>
    <div className="servi-flex-odd">
    <div className="services-box-left">
    <h1>3. Customer Churn</h1>
        <img src="/assets/ai3.svg" alt="" loading='lazy' />
    </div>
    <div className="services-box-right" >
     
      <h1>Customer Churn</h1>
      <p>In our increasingly competitive economy, customers will often have the option to choose between similar products and services, making it a key task to retain existing clients.

Using AI to predict customer churn could be one of the most important parts to prevent you and your business from losing customers and attracting new ones. We will make sure you won’t lose your income and business to your competitors.
.</p>

      <button onClick={()=>navigate('/contact')}>Contact Us</button>
    </div>

   
    </div>
    <div className="servi-flex">
    <div className="services-box-right"> 
   
    <h1>Case management system </h1>
      <p>Our cloud based case management systems are easy to use. Modelled around your processes and integrate with your existing solutions.

Its features include matter management, expense & time recording, legal billing, client accounting, document management, integration, security and support. Want a demo?

</p>
      <button onClick={()=>navigate('/contact')}>Contact Us</button>
    </div>
   
   
    <div className="services-box-left">
    <h1>4. Case management system </h1>
        <img src="/assets/ai4.svg" alt="" loading='lazy' />
    </div>
    </div>

    <div className="servi-flex-odd">
    <div className="services-box-left">
    <h1>5. Al Help Desk</h1>
        <img src="/assets/ai5.svg" alt="" loading='lazy' />
    </div>
    <div className="services-box-right" >
     
      <h1>Al Help Desk</h1>
      <p>Need a bespoke AI chatbot or customer service suite?

Many brands, such as JustEat, have embraced this and are reaping the rewards. Let your Organisation use this tech to your advantage.

.</p>

      <button onClick={()=>navigate('/contact')}>Contact Us</button>
    </div>

   
    </div>
  
   
    

  </div>
  )
}

export default Aibody