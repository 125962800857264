import { useNavigate } from 'react-router-dom'
import './Footer.css'

const Footer = () => {
    const navigate = useNavigate()
  return (
    <div className="footer-container">

    
    <div className='footer'>
        <div className="footer-top">
            <div className="footer-flex">
            <div className="footer-top-left">

               <h1> <img src="/assets/logi.svg" alt="" /> </h1>
                <p>First Floor</p>
                <p> Swan Buildings</p>
                <p>20 Swan Buildings</p>
                <p>City Centre</p>
                <p>Manchester</p>
                <p>M4 5JW</p>
                
            </div>
            <div className="footer-top-right-line">
            <h1>Our Services</h1>
                <p onClick={()=>navigate('/dataservices')}> Data Services</p>
                <p onClick={()=>navigate('/apps')}>Apps</p>
            
                <p onClick={()=>navigate('/artificialintelligence')}> Artificial Intelligence</p>
                <p onClick={()=>navigate('/consulting')}>Consulting </p>
                <p onClick={()=>navigate('/infrastructure')}>Infrastructure</p>
               
                <p  onClick={()=>navigate('/robotics')}>Robotic Process Automation</p>
               
            </div>
            <div className="footer-top-right-line">
                <h1>Tabs</h1>
                <p  onClick={()=>navigate('/')} > Home</p>
                <p  onClick={()=>navigate('/services')} > Our Services</p>
                <p  onClick={()=>navigate('/products')} >Projects</p>
                <p  onClick={()=>navigate('/products')} >Products</p>
                <p onClick={()=>navigate('/blogs')}>Blogs</p>
                <p  onClick={()=>navigate('/about')} >About </p>
               
               
               
                <p  onClick={()=>navigate('/contact')} >Contact</p>
                
        
              
               
                
            </div>
            </div>
            <div className="footer-line"></div>
            <div className="footer-flex">
            <div className="footer-top-right">
              
               <p>  <img src="/assets/cont2.svg" alt="" style={{filter: 'invert(100%)' , width: '20px' , marginRight: '10px'}} />  <a href="tel:+441613121772" style={{textDecoration: 'none' , color: 'white'}}> +44 1613121772 </a> </p> 
               
              <p>   <img src="/assets/cont3.svg" alt="" style={{filter: 'invert(100%)' , width: '20px' , marginRight: '10px'}} />  <a href="mailto:info@aipidata.com" style={{textDecoration: 'none' , color: 'white'}}> Info@aipidata.com </a> </p>  
               <p > <a href="/assets/AIPiData Limited - Modern Slavery Compliance statement 2022-23 FY.doc"  style={{textDecoration: 'none' , color: 'white'}}> Our modern slavery statment </a> </p>
                <p>VAT no. 314554321</p>
                <p>Company no. 10233637 </p>
             
               
            </div>
            <div className="footer-top-socials">
               <h1>Follow Us</h1>
                
                <div className="socials-container">
                    <a href="https://m.facebook.com/profile.php?id=100064326542964&_rdc=2&_rdr&refsrc=deprecated&paipv=0&eav=AfZwCcP2pT5kPYaR2a_IUR67zXGbE4TAFmOSDkiRQ5VwqAMqSU6h8Ik-boup4QozdxE&ref=104#_=_" target="_blank"> <div className="socials">
                        <img src="/assets/facebook.svg" alt="" />
                    </div>
                    </a>
                     <a href="https://mobile.twitter.com/aipidata" target="_blank"> <div className="socials">
                        <img src="/assets/twitter.svg" alt="" />
                    </div>
                    </a>
                    <a href="https://www.linkedin.com/company/alpidata-ltd/" target="_blank"> <div className="socials">
                        < img src="/assets/link.svg" alt="" />
                    </div>
                    </a>
                </div>
               <div className="copy-div">
                <img src="/assets/copyr.svg" alt=""  className='copy' /> <p> 2022 </p>
                </div>
            </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Footer