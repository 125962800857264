import './Culture.css'

const Culture = () => {
  return (
    <div>
         <div className="about-c-top">
            <img src="/assets/culture.svg" alt="" loading='lazy' />
        </div>
        <div className="about-c-bottom">
        <h1>GOOD TIMES</h1>
            <p> Having a team of people driven by the same ideology and mission is critical to maintaining a thriving culture. That is why at Aipidata our values are anchored on: passion, integrity, team and excellence.
            </p> 
              <br />
            <p> We care about getting the job done in a fun and friendly environment. Everyone has a voice and everyone matters.
            </p>
          
        </div>

    </div>
  )
}

export default Culture