import {BrowserRouter as Router , Routes ,Route} from 'react-router-dom'
import './App.css';
import ComingSoon from './components/ComingSoon/ComingSoon';
import Hamburger from './components/Hamburger/Hamburger';
import Navbar from './components/Home-components/Navbar/Navbar';
import AboutUs from './pages/AboutUs/AboutUs';
import Ai from './pages/Ai/Ai';
import Apps from './pages/Apps/Apps';
import Consulting from './pages/Consulting/Consulting';
import Cont from './pages/Cont/Cont';
import Contact from './pages/Contact/Contact';
import DataAnalytics from './pages/DataAnalytics/DataAnalytics';
import Home from './pages/Home/Home';
import Infrastructure from './pages/Infrastructure/Infrastructure';
import Initiative from './pages/Initiative/Initiative';
import OurServices from './pages/OurServices/OurServices';
import Projects from './pages/Projects/Projects';
import Robotics from './pages/Robotics/Robotics';
import ScrollToTop from './ScrollToTop/ScrollToTop';


function App() {



  return (
    <div className="App">
        <Router>
          
          <Navbar />
          <ScrollToTop>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<AboutUs />} />
            <Route path='/services' element={<OurServices />} />
            <Route path='/blogs' element={<Initiative />}/>
            <Route  path='/dataservices' element={<DataAnalytics />}/>
            <Route  path='/apps' element={<Apps/>}/>
            <Route  path='/artificialintelligence' element={<Ai/>}/>
            <Route  path='/consulting' element={<Consulting/>}/>
            <Route  path='/products' element={<Contact />}/>
            <Route path='/contact' element={<Cont />} />
            <Route path='/infrastructure' element={<Infrastructure />} />
            <Route path='robotics' element={<Robotics />} />
            <Route  path='/comingsoon' element={<ComingSoon />}/>
            <Route path='/projects' element={<Projects />} />
          </Routes>
          </ScrollToTop>
        </Router>
    </div>
  );
}

export default App;
