import './ConsultingBody.css'

const ConsultingBody = () => {
  return (
    <div className='section-2'>
      <h1>Talk To Us</h1>
      <div className='section-2-text'>
        <p>Our team of consultants can give hands on advice from infrastructure in the cloud or on premise.
        From change management, business intelligence, technological transformation, organisational change, infrastructure acquisition, business analysis, IoT, artificial intelligence, business analysis, visualization and data.</p>
      </div>

      <div className='section-pic'>
        <img src='/assets/con1.svg' alt="" />
        <img src='/assets/con2.svg' alt="" />
      </div>
    </div>
  )
}

export default ConsultingBody