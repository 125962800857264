import './WhyUs.css'
import { Fade } from 'react-reveal'
import { useState } from 'react'
const WhyUs = () => {
    const [first , setFirst ] = useState(true)
const [second , setSecond] = useState(false)
const [third , setThird] = useState(false)
const [fourth, setFourth]= useState(false )


const showFirst = ()=>{
    setFirst(true)
    setSecond(false)
    setThird(false)
    setFourth(false )
  

}

const showSecond = ()=>{
    setFirst(false)
    setSecond(true)
    setThird(false)
    setFourth(false )
  
}

const showThird = ()=>{
    setFirst(false)
    setSecond(false)
    setThird(true)
    setFourth(false )
    

}

const showFourth = ()=>{
    setFirst(false)
    setSecond(false)
    setThird(false)
    setFourth(true)
}
  return (

    <div className='whyus'>
        <div className="whyus-cont">
        <div className="whyus-heading">
            <h1>Why Us?</h1>
            </div>
            <div className="whyus-container">
                {first && 
              
                <div className="whyus-boxes">
                    <img src="/assets/prev.svg"  className='why-btn'   alt="" onClick={showThird}/>
                    <Fade right duration={300}>
                    <div className="why-only">
                        <img src="/assets/wh1.svg" alt="" />
                        <p>Aipidata helps businesses unlock value in their data. We leverage Al to drive decison intelligence within your organisation.</p>
                    </div>
                    </Fade>
                    <img src="/assets/next.svg"  className='why-btn'  alt="" onClick={showSecond} />
                </div>}
                {second && 
              
                <div className="whyus-boxes">
                    <img src="/assets/prev.svg"   className='why-btn' alt="" onClick={showFirst} />
                    <Fade duration={300} right>
                    <div className="why-only">
                        <img src="/assets/wh2.svg" alt="" onClick={showFirst}/>
                        <p>We are Simple , Solution-giving , Iterative , Data-driven and Performant  </p>
                    </div>
                    </Fade>
                    <img src="/assets/next.svg" alt=""  className='why-btn' onClick={showThird}  />
                </div>

                
                
                }
                {third && 
                
                <div className="whyus-boxes">
                    <img src="/assets/prev.svg" alt=""  className='why-btn' onClick={showSecond}  />
                    <Fade duration={300} right> 
                    <div className="why-only">
                        <img src="/assets/wh3.svg" alt="" />
                        <p>We boast of a vast team of professionals ranging from Engineers, Developers, Consultants tailored at giving the best services across the various sectors of industries.</p>
                    </div>
                    </Fade>
                    <img src="/assets/next.svg" alt=""  className='why-btn' onClick={showFirst}  />
                </div>
               }
            </div>
      
        </div>
    </div>
  )
}

export default WhyUs