import { useState } from "react"
import { useNavigate } from "react-router-dom"
const RoboticsBody = () => {

const navigate = useNavigate()
  return (
    <div className="services-flex">
    <div className="servi-flex-odd">

    
    <div className="services-box-left">
    <h1>1. Supply chain optimization</h1>
        <img src="/assets/ro1.svg" alt=""loading='lazy'/>
    </div>
    <div className="services-box-right" >
   
    <h1>Supply chain optimization</h1>
      <p>
        <ul>

          <li> Integrated supplier database
            <br />
            <br />
              Validate Suppliers  on receipt-coded invoices sent to suppliers
          </li>
          <br />
          
          <li>
            Trading term calculation
          <br />
         <br />
              Only pay suppliers on your terms when your terms are met
          
          </li>
          <br />
        
          <li>Suplier maintanance
            <br />
              <br />
            Full control interface for supplier maintanance.
          </li>
        </ul>
      </p>
      <button onClick={()=>navigate('/contact')}>Contact Us</button>
    </div>
    </div>
    <div className="servi-flex">
    <div className="services-box-right"> 
   
    <h1>Accounting and Financial Services </h1>
      <p>Using A.I algorithms, we are able to effectively implement RPA to handle activities such as operational accounting, budgeting and transactional operations.

</p>
      <button onClick={()=>navigate('/contact')}>Contact Us</button>
    </div>
   
   
    <div className="services-box-left">
    <h1>2. Accounting and Financial Services </h1>
        <img src="/assets/ro2.svg" alt="" loading='lazy' />
    </div>
    </div>
    <div className="servi-flex-odd">
    <div className="services-box-left">
    <h1>3. Human Resource Management</h1>
        <img src="/assets/ro3.svg" alt="" loading='lazy' />
    </div>
    <div className="services-box-right" >
     
      <h1>Human Resource Management</h1>
      <p>RPA is able to automate HR tasks, including onboarding and off boarding, employee information management and time sheet submission processes.</p>

      <button onClick={()=>navigate('/contact')}>Contact Us</button>
    </div>


   
    </div>
    

  
    

  </div>
  )
}

export default RoboticsBody