import './ComingSoon.css'

const ComingSoon = () => {
  return (
    <div className='Coming'>
        <h1>COMING SOON!</h1>
    </div>
  )
}

export default ComingSoon