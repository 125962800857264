import './IntroSection.css'
import { Fade } from 'react-reveal'
import { useNavigate } from 'react-router-dom'
const IntroSection = () => {
  const navigate = useNavigate()
  return (
    <div className="introsection-container">
    <div className='introsection'>

      <div className="intro-text">
     <Fade bottom delay={300}>  <h1>
      Aipidata  helps make your data Simple, Accessible, and Intelligent everytime.
      </h1></Fade>
      <Fade bottom delay={800}><button onClick={()=>navigate('/services')}>
        Learn More
      </button></Fade> 
      </div>
    </div>
    </div>
  )
}

export default IntroSection