import { Fade } from 'react-reveal'
import  './Invent.css'
import { useNavigate } from 'react-router-dom'

const Invent = () => {
  const navigate = useNavigate()
  return (
    <div className="invent-container">
    <div className='invent'>
        <div className="invent-left">
            <img src="/assets/hoo.svg" alt="" loading='lazy' />
        </div>
        <div className="invent-right">
          <Fade  delay={50}>  <h1>Invent, Inspire, Repeat</h1> </Fade>
          <Fade  delay={100}>  <p>We just don't unlock value in data, We are a Consultancy with speciality in <span style={{color: '#0E2A4A'}}> <span onClick={()=>navigate('/robotics')}> Robotics  </span>, <span onClick={()=>navigate('/robotics')}> Automation  </span>, <span onClick={()=>navigate('/artificialintelligence')}  >Artificial Intelligence</span>, <span onClick={()=>navigate('/dataservices')}> Data Engineering </span>, <span onClick={()=>navigate('/dataservices')}> Data Analytics and Visualisation. </span> </span>

What’s more! We help your organisation to be data driven. <br /> <br /> We are an innovative company that create socially impactful apps, check our      <span style={{color: '#0e2a4a' , textDecoration: 'underline'}} onClick={()=>navigate('/products')}>Tech4goodoffering.</span> 
</p></Fade>
           
        </div>
    </div>

    </div>
  )
}

export default Invent