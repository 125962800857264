import ConsultingBody from "../../components/Consulting-component/ConsultingBody"
import GetIn from "../../components/GetIn/GetIn"
import Footer from "../../components/Home-components/Footer/Footer"
import './Consulting.css'

const Consulting = () => {
  return (
    <div>
    <div className="cons-top">
      <div className="cons-cover">
          <h1>Consulting Services</h1>
          <p>Our App team are subject matter experts that create robust, reliable and cost-effective Apps, native Apps (stand-alone) and integrated Apps for various industries.</p>
          </div>
    </div>
  
  <ConsultingBody />
  <GetIn />

   <Footer />
   </div>
  )
}

export default Consulting