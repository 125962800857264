import './Selection.css'
import { useState } from 'react'
import Culture from './Culture/Culture'
import Company from './Company/Company'
const Selection = () => {

    const [one,setOne] = useState(true)
    const [two,setTwo] = useState(false)


    const showOne = ()=>{
        setOne(true)
        setTwo(false)
    }
    const showTwo =()=>{
        setTwo(true)
        setOne(false)
    }

  return (
    <div className='selection'>
        <div className="selection-heading-flex">
            <h1 onClick={showOne} style={ one ? {color:'black' ,borderBottom: '5px solid #2E7DDA'}: {color:'rgba(18, 18, 18, 0.3)' ,border: 'none;'}}>COMPANY</h1>
            <h1 onClick={showTwo} style={ two ? {color:'black' ,borderBottom: '5px solid #2E7DDA'}: {color:'rgba(18, 18, 18, 0.3)' ,border: 'none;'}}>CULTURE AND VALUES</h1>
        </div>

        {one &&  <Company />}
        {two &&  <Culture /> }
    </div>
  )
}

export default Selection