import GetIn from '../../components/GetIn/GetIn'
import Footer from '../../components/Home-components/Footer/Footer'
import InfrastructureBody from '../../components/Infrastructure-components/InfrastructureBody'
import './Infrastructure.css'

const Infrastructure = () => {
  return (
    <div>
      <div className="infra-top">
        <div className="infra-cover">
            <h1>Infrastructure</h1>
            <p>Scalable infrastructure on cloud or on premise. <br />  We got you !.</p>
            </div>
      </div>
    
     
   <InfrastructureBody />
   <GetIn />
     <Footer />

    </div>
  )
}

export default Infrastructure