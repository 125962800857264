import Footer from '../../components/Home-components/Footer/Footer'
import InitiativeBody from '../../components/InitiativeComponents/InitiativeBody'
import './Initiative.css'

const Initiative = () => {
  return (
    <>   
    <div className='initiative'>
       <div className="n-cover">
         <h1>Blogs</h1>
       </div>
    </div>  
    <InitiativeBody />
    <Footer />
    </>

  )
}

export default Initiative
