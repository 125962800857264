import Selection from '../../components/About-components/Selection'
import Footer from '../../components/Home-components/Footer/Footer'
import './AboutUs.css'

const AboutUs = () => {
  return (
    <div>
      <div className="about-top">
      <div className="n-cover">
         <h1>About</h1>
       </div>
      </div>
      <Selection />
      <Footer />
    </div>
  )
}

export default AboutUs