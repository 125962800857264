import Footer from '../../components/Home-components/Footer/Footer'
import ServicesDetails from '../../components/Services-components/ServicesDetilas/ServicesDetails'
import './OurServices.css'
const OurServices = () => {
  return (
    <div >
      <div className="services-top">
      <div className="n-cover">
         <h1>Our Services</h1>
       </div>
      </div>
      <ServicesDetails />
      <Footer />
    </div>
  )
}

export default OurServices