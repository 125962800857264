import Aibody from '../../components/Ai-components/Aibody'
import GetIn from '../../components/GetIn/GetIn'
import Footer from '../../components/Home-components/Footer/Footer'
import './Ai.css'

const Ai = () => {
  return (
    <div>
    <div className="Ai-top">
      <div className="Ai-cover">
          <h1>Artificial Intelligence</h1>
          <p>We help you work out what your organisation can do with AI and Machine Learning , Build and optimise your algorithms and help you implement it.</p>
          </div>
    </div>
  
   <Aibody />
   <GetIn />
   <Footer />
   </div>
  )
}

export default Ai