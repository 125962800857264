import { NavLink } from 'react-router-dom'
import Hamburger from '../../Hamburger/Hamburger'
import './Navbar.css'
import { useNavigate } from 'react-router-dom'
const Navbar = () => {
  const navigate = useNavigate()
  return (
    <div className="navbar-container">
    <div className='navbar'>
      <img src="/assets/logi.svg" alt="" onClick={()=>navigate('/')} />
        <div className="navlink">
            <NavLink to='/'>Home</NavLink>
            <NavLink to='/services'>Our Services</NavLink>
            <NavLink  to='/projects'>Projects</NavLink>
            <NavLink to='/products'>Products</NavLink>
            <NavLink to='/blogs'>Blogs</NavLink>
            <NavLink to='/about'>About</NavLink>
            <NavLink to='/contact'>Contact</NavLink>
            
        </div>
       
        <Hamburger />
    </div>
    </div>
  )
}

export default Navbar