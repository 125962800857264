import { useState } from 'react'
import { Fade } from 'react-reveal'
import './Features.css'




const Features = () => {

const [first , setFirst ] = useState(true)
const [second , setSecond] = useState(false)
const [third , setThird] = useState(false)
const [fourth, setFourth]= useState(false )


const showFirst = ()=>{
    setFirst(true)
    setSecond(false)
    setThird(false)
    setFourth(false )
  

}

const showSecond = ()=>{
    setFirst(false)
    setSecond(true)
    setThird(false)
    setFourth(false )
  
}

const showThird = ()=>{
    setFirst(false)
    setSecond(false)
    setThird(true)
    setFourth(false )
    

}

const showFourth = ()=>{
    setFirst(false)
    setSecond(false)
    setThird(false)
    setFourth(true)
   

}
  return (
    <div className='features'>
      <img src="/assets/poly.svg" alt="" className='poly' />
      <div className="features-flex-container">
       {first &&
       <Fade right duration={300}>
        <div className="features-flex" >
            <p>
            <h3>PARTNERSHIP MODEL</h3>
            Our deeply integrated partnerships are highly effective at delivering data change within organisations.
            </p>
            <img src="/assets/fe1.svg" alt="" />
            <img src="/assets/triangle.svg" alt="" id='tri1' />
       </div>
       
       </Fade>}
       {second  &&
       <Fade  right duration={300}>
        <div className="features-flex">
            <p>

           
            <h3>STRATEGY & EXECUTION</h3>
            Our consultants brings a powerful breadth of data experience, spanning strategy and operations to engineering and analytics.
            </p>
            <img src="/assets/fe2.svg" alt=""  />
            <img src="/assets/triangle.svg" alt="" id='tri2' />
       </div>
       </Fade>}
       {third &&
       <Fade right duration={300}>
        <div className="features-flex">
       
            <p>
            <h3>ACCOUNTABILITY</h3>
            We have ‘skin in the game’ through the way we embed our people, and in terms of how we structure and deliver our engagements.
            </p>
            <img src="/assets/fe3.svg" alt="" />
            <img src="/assets/triangle.svg" alt="" id='tri3' />
       </div>
       </Fade>}
       {fourth &&
       <Fade right duration={300}>
        <div className="features-flex">
          <ul>
            <h3>OUTCOMES</h3>
            <li>We won't make you outspend your competiton, you outthink them.</li>
            <li>We provide personalized experiences for customers and prospects.</li>
            <li>Wide ranging improvement of operational efficiency and effectiveness through your supply chain.</li>
          </ul>
            <img src="/assets/fe4.svg" alt="" />
            <img src="/assets/triangle.svg" alt="" id='tri4' />
       </div>
       </Fade>}
       </div>

       <div className="features-tab">
        <div onClick={showFirst} className='features-tab-div'>
        <p  style={first ? { color: 'white'} : {color: 'rgba(255, 255, 255, 0.42)'}}>1. </p>
        <div className='web-p' style={first ? { color: 'white'} : {color: 'rgba(255, 255, 255, 0.42)'}}>  PARTNERSHIP MODEL </div>
        </div>
        <div onClick={showSecond} className='features-tab-div' >
        <p  style={second ? { color: 'white'} : {color: 'rgba(255, 255, 255, 0.42)'}}>2. </p>
        <div className="web-p" style={second ? { color: 'white'} : {color: 'rgba(255, 255, 255, 0.42)'}}>  STRATEGY & EXECUTION </div>
        </div>
        <div onClick={showThird} className='features-tab-div'>
        <p  style={third ? { color: 'white'} : {color: 'rgba(255, 255, 255, 0.42)'}}> 3. </p>
        <div className="web-p" style={third ? { color: 'white'} : {color: 'rgba(255, 255, 255, 0.42)'}}> ACCOUNTABILITY </div>
        </div>
        <div onClick={showFourth} className='features-tab-div'>
        <p  style={fourth ? { color: 'white'} : {color: 'rgba(255, 255, 255, 0.42)'}}>4. </p>
        <div className="web-p" style={fourth ? { color: 'white'} : {color: 'rgba(255, 255, 255, 0.42)'}}> OUTCOMES </div>
        </div>
       </div>
       
    </div>
  )
}

export default Features