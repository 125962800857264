import Certifications from '../../components/Home-components/Certifications/Certifications'
import Features from '../../components/Home-components/Features/Features'
import Footer from '../../components/Home-components/Footer/Footer'
import GetStarted from '../../components/Home-components/GetStarted/GetStarted'
import IntroSection from '../../components/Home-components/IntroSection/IntroSection'
import Invent from '../../components/Home-components/Invent/Invent'
import SignUp from '../../components/Home-components/SignUp/SignUp'
import Testimonials from '../../components/Home-components/Testimonials/Testimonials'
import WhyUs from '../../components/Home-components/WhyUs/WhyUs'

import './Home.css'

const Home = () => {
  return (
    <div className='home'>
        <IntroSection />
        <Invent />
        <Features />
        <Testimonials />
        <WhyUs />
        <GetStarted />
        <Certifications />
        <SignUp />
        <Footer />
    </div>
  )
}

export default Home