import  './Hamburger.css'
import { NavLink, useNavigate } from 'react-router-dom'
import { useState, useRef} from 'react'
import { Fade } from 'react-reveal'

const Hamburger = () => {
    const burg =  useRef()
    const [show , setShow ] = useState(false)
    const [logout , setLogout]  = useState(false)

 
    const toggle = ()=>{
        setShow(!show)


    }

    const showLogout = ()=>{
        burg.current.classList.remove('fade-in-left')
            burg.current.classList.add('fade-out-left')
       
        setLogout(true)
    }

    const reset = () =>{
       setShow(false)
    }

  return (
    <div className="burger-container">
 <div className="burger-line" >
        <img src="/assets/haam.svg" alt="" style={{width: '30px'}} onClick={toggle} />
    </div>




  {show &&  <div className='hamburger' ref={burg} >
         <div className="burger" >
             
                <div className="burger-links">
                    <NavLink  to='/' className="dashboard-sidebar-links" onClick={reset}>
                        <img src="/assets/Home.svg" alt=""  />
                        <p>Home</p>
                        <div className='dashboard-sidebar-line'>
                            
                        </div>
                    </NavLink>
                    <NavLink to='/services' className="dashboard-sidebar-links"  onClick={reset}>
                        <img src="/assets/Transactions.svg" alt=""  />
                        <p>Our Services</p>
                        <div className='dashboard-sidebar-line'>
                            
                        </div>
                    </NavLink>
                    <NavLink to='/projects' className="dashboard-sidebar-links"  onClick={reset}>
                        <img src="/assets/refunds.svg" alt=""  />
                        <p>Projects</p>
                        <div className='dashboard-sidebar-line'>                        
                        </div>
                    </NavLink>
                    <NavLink to='/products' className="dashboard-sidebar-links"  onClick={reset}>
                        <img src="/assets/refunds.svg" alt=""  />
                        <p>Products</p>
                        <div className='dashboard-sidebar-line'>                        
                        </div>
                    </NavLink>
                    <NavLink to='/blogs' className="dashboard-sidebar-links"  onClick={reset}>
                        <img src="/assets/refunds.svg" alt=""  />
                        <p>Blogs</p>
                        <div className='dashboard-sidebar-line'>                        
                        </div>
                    </NavLink>
                    <NavLink to='/about' className="dashboard-sidebar-links"  onClick={reset}>
                        <img src="/assets/refunds.svg" alt=""  />
                        <p>About</p>
                        <div className='dashboard-sidebar-line'>                        
                        </div>
                    </NavLink>
                   
                  
                    <NavLink to='/contact' className="dashboard-sidebar-links"  onClick={reset}>
                        <img src="/assets/refunds.svg" alt=""  />
                        <p>Contact</p>
                        <div className='dashboard-sidebar-line'>                        
                        </div>
                    </NavLink>
                   
                </div>
           
         </div>
    </div>  
}
        {logout && <div className='cover'>
            </div>}
    </div>
  )
}

export default Hamburger