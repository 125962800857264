import React from 'react'
import GetIn from '../../components/GetIn/GetIn'
import Footer from '../../components/Home-components/Footer/Footer'
import ProductBody from '../../components/Products-component/ProductBody'
import './Projects.css'
const Projects = () => {
  return (
    <div className='projects'>
          <div className="projects-top">
          <div className="n-cover">
         <h1>Projects</h1>
       </div>
         </div>

         <ProductBody />
         <GetIn />
         <Footer />
    </div>
  )
}

export default Projects