import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'


const AppsBody = () => {

  const [one , setOne ] = useState(true)
  const [two , setTwo] = useState(false)

  const showOne = () =>{
    setOne(true)
    setTwo(false)
  }
  const showTwo = () =>{
    setOne(false)
    setTwo(true)
  }



    const navigate = useNavigate()
  return (
    <div className="services-flex">
    <div className="servi-flex-odd">

    
    <div className="services-box-left">
    <h1>1. Cur-8</h1>
        <img src="/assets/ap1.svg" alt=""loading='lazy'/>
    </div>
    <div className="services-box-right" >
   
    <h1>Cur-8</h1>
      <p>Project Management is at the core of every business, from sole traders to larger organisations. Our Cur-8 app is built to help you effectively engage and manage relationships, whilst keeping track of your goals and workload management.
        <br />
        <br />
      Cur-8 has many unique features to make project management efficient and easy.
      <br /> 
      <br />
      Cur-8 uses Artificial Intelligence to suggest free time available for certain tasks based on your personal and work calendars , as well as automating and templating emails based on project statuses. 






</p>
      <button onClick={()=>navigate('/contact')} >Contact Us</button>
    </div>
    </div>
    <div className="servi-flex">
    <div className="services-box-right"> 
   
    <h1>Web Portals </h1>
      <p>Let us help you create custom secure web portals, which allows your team and customers to access productivity tools. 

</p>
      <button onClick={()=>navigate('/contact')} >Contact Us</button>
    </div>
   
   
    <div className="services-box-left">
    <h1>2. Web Portals </h1>
        <img src="/assets/ap2.svg" alt="" loading='lazy' />
    </div>
    </div>
    <div className="servi-flex-odd">
    <div className="services-box-left">
    <h1>3. Bespoke Apps</h1>
        <img src="/assets/ap3.svg" alt="" loading='lazy' />
    </div>
    <div className="services-box-right" >
     
      <h1>Bespoke Apps</h1>
      <p>Do you need custom pieces of software for your customers and your operations, which are easily accessible and easy to use and require little to no training?

.</p>

      <button onClick={()=>navigate('/contact')} >Contact Us</button>
    </div>

   
    </div>
    <div className="servi-flex">
    <div className="services-box-right"> 
   
    <h1>Case management system </h1>
      <p>Our cloud based case management systems are easy to use. Modelled around your processes and integrate with your existing solutions.

Its features include matter management, expense & time recording, legal billing, client accounting, document management, integration, security and support. Want a demo?

</p>
      <button onClick={()=>navigate('/contact')} >Contact Us</button>
    </div>
   
   
    <div className="services-box-left">
    <h1>4. Case management system </h1>
        <img src="/assets/ap4.svg" alt="" loading='lazy' />
    </div>
    </div>

    <div className="servi-flex-odd">
    <div className="services-box-left">
    <h1>5. User Interface & User Experience</h1>
        <img src="/assets/ap5.svg" alt="" loading='lazy' />
    </div>
    <div className="services-box-right" >
     
      <h1>User Interface & User Experience</h1>
      <p>The product experience to the user starts from the interface they interact with and not the backend solution. We build highly performant and easy to use interfaces that require little or no training.

For your user experience, user interfaces, customer experience, usability research, navigation experience and customer research.

.</p>

      <button onClick={()=>navigate('/contact')} >Contact Us</button>
    </div>

   
    </div>

    <div className="cto">
      <h1>Go Deeper</h1>
      <p>Learn more about our exclusive services</p>
    </div>

    <div className="selection-heading-flex">
            <h1 onClick={showOne} style={ one ? {color:'black' ,borderBottom: '5px solid #2E7DDA'}: {color:'rgba(18, 18, 18, 0.3)' ,border: 'none;'}}>Risk Design Apps</h1>
            <h1 onClick={showTwo} style={ two ? {color:'black' ,borderBottom: '5px solid #2E7DDA'}: {color:'rgba(18, 18, 18, 0.3)' ,border: 'none;'}}>Appointment Prediction App</h1>
        </div>

   {one && <div className="servi-flex-odd">

      
<div className="services-box-left">
  <h1>1. Risk Design Apps</h1>
    <img src="/assets/deep1.svg" alt=""loading='lazy'/>
</div>
<div className="services-box-right" >

<h1>Risk Design Apps</h1>
  <p>We create apps that can stratify and measure business and operational risk.
</p>
  <button onClick={()=>navigate('/dataservices')}>Learn More</button>
</div>
</div> }
    {two &&  <div className="servi-flex-odd">

      
<div className="services-box-left">
  <h1>1. Appointment Prediction App</h1>
    <img src="/assets/deep2.svg" alt=""loading='lazy'/>
</div>
<div className="services-box-right" >

<h1>Appointment Prediction App</h1>
  <p>The app predicts volumes of GP appointments as well as who is likely to cancel.

It also aggregates patient data to and it's displayed in a hand held device by the GP. Want a Demo?</p>
  <button onClick={()=>navigate('/dataservices')}>Learn More</button>
</div>
</div> }

   
    

  </div>
  )
}

export default AppsBody