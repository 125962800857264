import { useState , useEffect} from "react"
import { Fade } from "react-reveal"

import './Certifications.css'
const Certifications = () => {
    const [first , setFirst ] = useState(true)
    const [second , setSecond] = useState(false)
    const [third , setThird] = useState(false)
    const [fourth , setFourth] = useState(false)
    const [fifth , setFifth] = useState(false)
    const [sixth , setSixth] = useState(false )
    const [seventh , setSeventh] = useState(false)
    const [eight , setEight ] = useState(false)
    const [ninth , setNinth ] = useState(false)
    const [tenth , setTenth ] = useState(false)
    const [elevnth , setEleventh] = useState(false)
    const [twelvth , setTwelvth] = useState(false)
    const [thirth , seThirth] = useState(false)
    const [fourthe , setFourthe ] = useState(false)
    const showFirst = ()=>{
        setFirst(true)
        setSecond(false)
        setThird(false)
        setFourth(false)
        setFourth(false)
        setFifth(false )
        setSixth(false)
        setSeventh(false)
        setEight(false)
        setNinth(false)
        setTenth(false)
        setEleventh(false)
        setTwelvth(false)
        seThirth(false)
        setFourthe(false)
    
    }
    
    const showSecond = ()=>{
        setFirst(false)
        setSecond(true)
        setThird(false)
        setFourth(false)
        setFifth(false )
        setSixth(false)
        setSeventh(false)
        setEight(false)
        setNinth(false)
        setTenth(false)
        setEleventh(false)
        setTwelvth(false)
        seThirth(false)
        setFourthe(false)
    }
    
    const showThird = ()=>{
        setFirst(false)
        setSecond(false)
        setThird(true)
        setFourth(false)
        setFifth(false )
        setSixth(false)
        setSeventh(false)
        setEight(false)
        setNinth(false)
        setTenth(false)
        setEleventh(false)
        setTwelvth(false)
        seThirth(false)
        setFourthe(false)    
    
    }

    const showFourth= ()=>{
        setFirst(false)
        setSecond(false)
        setThird(false)
        setFourth(true)
        setFifth(false )
        setSixth(false)
        setSeventh(false)
        setEight(false)
        setNinth(false)
        setTenth(false)
        setEleventh(false)
        setTwelvth(false)
        seThirth(false)
        setFourthe(false)
    }
    const showFifith= ()=>{
        setFirst(false)
        setSecond(false)
        setThird(false)
        setFourth(false)
        setFifth(true )
        setSixth(false)
        setSeventh(false)
        setEight(false)
        setNinth(false)
        setTenth(false)
        setEleventh(false)
        setTwelvth(false)
        seThirth(false)
        setFourthe(false)
    }
    const showSixth= ()=>{
        setFirst(false)
        setSecond(false)
        setThird(false)
        setFourth(false)
        setFifth(false)
        setSixth(true)
        setSeventh(false)
        setEight(false)
        setNinth(false)
        setTenth(false)
        setEleventh(false)
        setTwelvth(false)
        seThirth(false)
        setFourthe(false)
    }
    const showSeventh= ()=>{
        setFirst(false)
        setSecond(false)
        setThird(false)
        setFourth(false)
        setFifth(false)
        setSixth(false)
        setSeventh(true)
        setEight(false)
        setNinth(false)
        setTenth(false)
        setEleventh(false)
        setTwelvth(false)
        seThirth(false)
        setFourthe(false)
    }
    const showEight= ()=>{
        setFirst(false)
        setSecond(false)
        setThird(false)
        setFourth(false)
        setFifth(false)
        setSixth(false)
        setSeventh(false)
        setEight(true)
        setNinth(false)
        setTenth(false)
        setEleventh(false)
        setTwelvth(false)
        seThirth(false)
        setFourthe(false)
    }
    const showNineth= ()=>{
        setFirst(false)
        setSecond(false)
        setThird(false)
        setFourth(false)
        setFifth(false)
        setSixth(false)
        setSeventh(false)
        setEight(false)
        setNinth(true)
        setTenth(false)
        setEleventh(false)
        setTwelvth(false)
        seThirth(false)
        setFourthe(false)
    }
    const showTenth= ()=>{
        setFirst(false)
        setSecond(false)
        setThird(false)
        setFourth(false)
        setFifth(false)
        setSixth(false)
        setSeventh(false)
        setEight(false)
        setNinth(false)
        setTenth(true)
        setEleventh(false)
        setTwelvth(false)
        seThirth(false)
        setFourthe(false)
    }
    const showEleven = ()=>{
        setFirst(false)
        setSecond(false)
        setThird(false)
        setFourth(false)
        setFifth(false)
        setSixth(false)
        setSeventh(false)
        setEight(false)
        setNinth(false)
        setTenth(false)
        setEleventh(true)
        setTwelvth(false)
        seThirth(false)
        setFourthe(false)
    }
    const showTwelve =()=>{
        setFirst(false)
        setSecond(false)
        setThird(false)
        setFourth(false)
        setFifth(false)
        setSixth(false)
        setSeventh(false)
        setEight(false)
        setNinth(false)
        setTenth(false)
        setEleventh(false)
        setTwelvth(true)
        seThirth(false)
        setFourthe(false)
    }
    const showThirteen =()=>{
        setFirst(false)
        setSecond(false)
        setThird(false)
        setFourth(false)
        setFifth(false)
        setSixth(false)
        setSeventh(false)
        setEight(false)
        setNinth(false)
        setTenth(false)
        setEleventh(false)
        setTwelvth(false)
        seThirth(true)
        setFourthe(false)
    }
    const showFourteen =()=>{
        setFirst(false)
        setSecond(false)
        setThird(false)
        setFourth(false)
        setFifth(false)
        setSixth(false)
        setSeventh(false)
        setEight(false)
        setNinth(false)
        setTenth(false)
        setEleventh(false)
        setTwelvth(false)
        seThirth(false)
        setFourthe(true)
    }
    useEffect(()=>{
        const Interval = setInterval(()=>{
                   if(first){
                    showSecond()
                   }
                   else if(second){
                    showThird()
                   }
                   else if(third){
                    showFourth()
                   }
                   else if(fourth){
                    showFifith()
                   }
                   else if(fifth){
                    showSixth()
                   }
                   else if(sixth){
                    showSeventh()
                   }
                   else if(seventh){
                    showEight()
                   }
                   else if(eight){
                    showNineth()
                   }
                   else if(ninth){
                    showTenth()
                   }
                   else if(tenth){
                    showEleven()
                   }
                   else if(elevnth){
                    showTwelve()
                   }
                   else if(twelvth){
                    showThirteen()
                   }
                   else if(thirth){
                    showFourteen()
                   }
                   else if(fourthe){
                    showFirst()
                   }
                    
                },2000)
                return () => {
                            // clearInterval(setIntervalOne)
                            // clearInterval(setIntervalTwo)
                clearInterval(Interval)
               
             }
    
    },[first , second , third , fourth , fifth , sixth , seventh , eight , ninth , tenth , elevnth , twelvth , thirth , fourthe])
  return (
    <div className='certifications'>
        <div className="cirtifications-flex">
            <div className="certifications-left">
                <h1> Our Tech Stack </h1>
                <p> we can set you up, or read more about our <br /> services here</p>
                <img src="/assets/dots.svg" alt="" />
                <img src="/assets/dimg.svg" alt="" id="dot-img"/>
            </div>
           <div className="certifications-right">
           {first && <img src="/assets/c1.svg" /> }
             {second && <img src="/assets/cc9.svg" /> }
         {third && <img src="/assets/cc11.svg" /> }
         {fourth && <img src="/assets/cc1.svg" alt="" />}
                {fifth && <img src="/assets/cc2.svg" alt="" />}
                {sixth && <img src="/assets/cc3.svg" alt="" />}
                {seventh &&<img src="/assets/cc4.svg" alt="" />}
                {eight && <img src="/assets/cc5.svg" alt="" />}
                {ninth && <img src="/assets/cc6.svg" alt="" />}
                {tenth && <img src="/assets/cc7.svg" alt="" />}
               
                {elevnth && <img src="/assets/cc8.svg" alt="" />}
               
                {twelvth && <img src="/assets/cc9.svg" alt="" />}
               
                {thirth && <img src="/assets/cc10.svg" alt="" />}
               
                {fourthe && <img src="/assets/cc12.svg" alt="" />}
               
            </div>
           
           <div className="certifications-right-mobile">
            <h1>Our Tech Stack</h1>
            <div className="right-mobile-flex">
                {first && <img src="/assets/c1.svg" /> }
             {second && <img src="/assets/cc9.svg" /> }
         {third && <img src="/assets/cc11.svg" /> }
         {fourth && <img src="/assets/cc1.svg" alt="" />}
                {fifth && <img src="/assets/cc2.svg" alt="" />}
                {sixth && <img src="/assets/cc3.svg" alt="" />}
                {seventh &&<img src="/assets/cc4.svg" alt="" />}
                {eight && <img src="/assets/cc5.svg" alt="" />}
                {ninth && <img src="/assets/cc6.svg" alt="" />}
                {tenth && <img src="/assets/cc7.svg" alt="" />}
                {elevnth && <img src="/assets/cc8.svg" alt="" />}
               
               {twelvth && <img src="/assets/cc9.svg" alt="" />}
              
               {thirth && <img src="/assets/cc10.svg" alt="" />}
              
               {fourthe && <img src="/assets/cc12.svg" alt="" />}
         </div>
           
            </div>
        </div>
    </div>
  )
}

export default Certifications