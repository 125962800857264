import './InitiativeBody.css'

const InitiativeBody = () => {
  return (
    <div className='initiative-body'>
        <div className="initiative-heading">
            <h1>CURRENT INTIATIVES</h1>
        </div>
        <div className="initiative-flex">
            <div className="initiative-box-odd">
                <div className="initiative-left">
                    <h4>1. Aipidata are proud to be supporting the St Vincent de Paul Society.</h4>
                    <img src="/assets/st.svg" alt=""  loading='lazy'/>
                </div>
                <div className="initiative-right">
                    <h1>
                    Aipidata are proud to be supporting the St Vincent de Paul Society.
                    </h1>
                    <p>
                    We pride ourselves as a socially responsible Organisation. Therefore, we strive to ensure that in service and product delivery, we participate in fair-trade with our partners and customers, play our part in reducing carbon footprints by not depleting the ozone layer in our products manufacture, Charitable giving, Conscious Recycling, Volunteering in the community, Corporate policies that benefit the environment, Socially and environmentally conscious investments.
                    </p>
                </div>
            </div>
            <div className="initiative-box">
            <div className="initiative-right">
                    <h1>
                    Here's what they do:
                     </h1>
                    <p>

                    The Society of St Vincent de Paul (SVP or SVdP or SSVP) is an international voluntary organization in the Catholic Church, founded in 1833 for the sanctification of its members by personal service of the poor.
                    Innumerable Catholic parishes have established "conferences", most of which affiliate with a diocesan council. Among its varied efforts to offer material help to the poor or needy, the Society also has thrift stores which sell donated goods at a low price and raise money for the poor. There are a great variety of outreach programs sponsored by the local conferences and councils, addressing local needs for social services.
                    </p>
                </div>
                <div className="initiative-left">
                    <h4>2. Here's what they do:</h4>
                    <img src="/assets/ini2.svg" alt="" loading='lazy' />
                </div>
                
            </div>
        </div>

        <div className="blogs-text">
            <div className="blogs-heading">
                <h1>NEWS</h1>
            </div>
            <div className="blogs-text-container">
            <div className="blogs-img-box">
                    <img src="/assets/insider.png" alt="yahoo" />
                </div>
                <div className="blogs-body">
              <p> Business Insider Magazine Feature: AIpidata Launches Its  Pi-Health App to Bridge the Gaps of Non-Verbal Patients </p>
              <a href="https://markets.businessinsider.com/news/stocks/aipidata-launches-its-pihealth-app-to-bridge-the-gaps-of-nonverbal-patients-1031729184"  target="_blank">Read More</a>
              </div>
            </div>
            <div className="blogs-text-container">
            <div className="blogs-img-box">
                    <img src="/assets/newsfile.webp" alt="yahoo" />
                </div>
                <div className="blogs-body">
              <p>Newsfile Corp: AIpidata Launches Its Pi-Health App to Bridge the Gaps of Non-Verbal Patients </p>
              <a href="https://www.newsfilecorp.com/release/136238/AIpidata-Launches-Its-PiHealth-App-to-Bridge-the-Gaps-of-NonVerbal-Patients"  target="_blank">Read More</a>
              </div>
            </div>
            <div className="blogs-text-container">
                <div className="blogs-img-box">
                    <img src="/assets/yahoonews.png" alt="yahoo" />
                </div>
              <div className="blogs-body">   
              <p>Yahoo News: AIpidata Launches Its Pi-Health App to Bridge the Gaps of Non-Verbal Patients </p>
              <a href="https://finance.yahoo.com/news/aipidata-launches-pi-health-app-124600701.html?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAHPk4egkHIfc7D0YzejOHvS4pX10J64ZRTpdQscKfhVtQ60yKT8CoNtGcskXzymOCzlFp4JA_rJ8kRbSysj4NgY4-SaSpUqlWuiUrSNFMlWJd_zHNZlnLD3Mw-FY3GdX4IsqHGC3-WvWkAT_xCtftdUDHkjBJCs3liWCLLUTeENr" target="_blank">Read More</a>
              </div>
            </div>
        </div>
    </div>
  )
}

export default InitiativeBody