import { useNavigate } from 'react-router-dom'
import './DataAnybody.css'

const DataAnybody = () => {

  const navigate = useNavigate()
  return (
    <div className="services-flex">
    <div className="servi-flex-odd">

    
    <div className="services-box-left">
    <h1>1. Data Warehousing & Vaults</h1>
        <img src="/assets/da1.svg" alt=""loading='lazy'/>
    </div>
    <div className="services-box-right" >
   
    <h1>Data Warehousing & Vaults</h1>
      <p>Structure filtered data with our data warehousing solutions. Aggregate all your disparate data sources eg: Excel, Flat files, Outlook, Machine Learning outputs and other various sources into one single version for better data driven solutions. 

Both cloud or on-premise ETL/ELT methods, use our data vault solutions to store your raw data on the go. Whatever your needs, we have a solution.</p>
      <button  onClick={()=>navigate('/contact')} >Contact Us</button>
    </div>
    </div>
    <div className="servi-flex">
    <div className="services-box-right"> 
   
    <h1> Data Quality & Cleansing </h1>
      <p>Poor Data leads to poor insights as well as reduced efficiency. Recognising problem spots in data cleansing and applying rules help improve data quality.

To unlock the potential of data, problem spots need to be identified, cleansed, validated, mastered, and profiled.

Processes need to be set in place to ensure data meets quality rules and tracks progress over time. Let your data run a marathon and still produce outstanding wins.</p>
      <button  onClick={()=>navigate('/contact')} >Contact Us</button>
    </div>
   
   
    <div className="services-box-left">
    <h1> 2. Data Quality & Cleansing </h1>
        <img src="/assets/da2.svg" alt="" loading='lazy' />
    </div>
    </div>
    <div className="servi-flex-odd">
    <div className="services-box-left">
    <h1>3. Visualisation & Analytics</h1>
        <img src="/assets/da3.svg" alt="" loading='lazy' />
    </div>
    <div className="services-box-right" >
     
      <h1>Visualisation & Analytics</h1>
      <p>Unlock business insights, track KPI’s, monitor, visualise results, analyse, stream Live data, slice and dice & check your business health over-time variations, turbo charge decision making using our insightful dashboards and reporting solutions.

We can also plug into existing systems. Create alerts, get log reports and escalate; create user stories and map out strategies using our powerful reporting solutions.

Present live dashboards to your stakeholders, as PowerPoint slides using our data and visualisation solutions.</p>

      <button  onClick={()=>navigate('/contact')} >Contact Us</button>
    </div>


   
    </div>
    

    <div className="servi-flex">
    <div className="services-box-right"> 
   
    <h1>Data Architecture </h1>
      <p>Data needs to be structured to get the most out of it. A solid architecture as well as design and control of its flow from source to by-product is essential in data life cycles.

A great data model should ensure interactions between all data systems as well as setting standards that align with core business processes and activities of the organisation.

Why us? Our team of seasoned architects are excellent at modelling processes and data sources.

They have been involved in extensive data migrations, both on-premise and Cloud-based, and are subject matter experts with complex competencies that produce simple solutions.</p>
      <button  onClick={()=>navigate('/contact')} >Contact Us</button>
    </div>
   
   
    <div className="services-box-left">
    <h1> 4. Data Architecture </h1>
        <img src="/assets/da4.svg" alt="" loading='lazy' />
    </div>
    </div>

    <div className="cto">
      <h1>Go Deeper</h1>
      <p>Learn more about our exclusive services</p>
    </div>
   
    

  </div>
  )
}

export default DataAnybody