
import './ContactBody.css'
import { useNavigate } from 'react-router-dom'
const ContactBody = () => {

    const navigate = useNavigate()

  return (
    <div className='contact-body'>  
        <div className="contactbody-heading">
            <h1>OUR PRODUCTS</h1>
            <p>We provide a wide range of professional products to meet your needs. We promise to  provide every product with a smile and to your highest level of satisfaction.</p>
        </div>
        <div className="contact-body-boxes-flex">
            <div className="contact-body-boxes">
            <h4>Cur-8</h4>
                <img src="/assets/cur.svg" alt="" />
                <h3>Cur-8</h3>
                <p>Our Cur-8 app is built to help you engage & manage relationships, whilst keeping track of your goals.
</p>
                <button onClick={()=>navigate('/apps')}>Learn More</button>
            </div>
            <div className="contact-body-boxes">
            <h4>Pi-Health</h4>
                <img src="/assets/pi.svg" alt="" />
                <h3>Pi-Health</h3>
                <p>AI powered remote end to end healthcare platform for vulnerable patients.
</p>
    <a href="https://www.pi-health.co.uk/" target="_blank"> <button onClick={()=>navigate('/comingsoon')}>Learn More</button> </a>
            </div>
            <div className="contact-body-boxes">
            <h4>Lektore</h4>
                <img src="/assets/lekt.svg" alt="" />
                <h3>Lektore</h3>
                <p>Lektore provides assimilation pathways for safe immigration to English speaking countries
</p>
<button onClick={()=>navigate('/comingsoon')}>Learn More</button>
            </div>
        </div>
    </div>
  )
}

export default ContactBody