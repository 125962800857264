import ContactBody from "../../components/Contact-components/ContactBody"
import Footer from "../../components/Home-components/Footer/Footer"
import './Contact.css'

const Contact = () => {
  return (
    <div >
    <div className="contact-top">
    <div className="n-cover">
         <h1>Our Products</h1>
       </div>
    </div>
    <ContactBody />
    <Footer />
  </div>
  )
}

export default Contact