import './GetIn.css'
import { useNavigate } from 'react-router-dom'

const GetIn = () => {
    const navigate = useNavigate()
  return (

    <div className="get-container">
    <div className='get'>
        <h1>Let’s get in touch</h1>
        <p>Take control of your business with our outstanding tools and solutions. With advanced reporting & analytics, you’ll be able to run your business efficiently and reduce unpredictability.

</p>
<button onClick={()=>navigate('/contact')}>Contact Us</button>
    </div>
    </div>
  )
}

export default GetIn